import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faStar as emptyStar } from '@fortawesome/free-solid-svg-icons'; // Import star icons
import './styles/PrivateBnBDetails.css'; // Add custom styles for the details page

const PrivateBnBDetails = () => {
  const { id } = useParams();
  const [home, setHome] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    axios
      .get(`https://khah-184f534d3b81.herokuapp.com/api/private_homes/${id}`)
     // .get(`http://localhost:3004/api/private_homes/${id}`)
      .then((response) => {
        setHome(response.data);
      })
      .catch((err) => {
        setError('Failed to load home details. Please try again later.');
        console.error(err);
      });
  }, [id]);

  // Function to render star ratings based on the rating value
  const renderStars = (rating) => {
    const stars = [];
    for (let i = 1; i <= 5; i++) {
      if (i <= rating) {
        stars.push(<FontAwesomeIcon icon={faStar} key={i} className="star-icon" />);
      } else if (i === Math.ceil(rating) && rating % 1 !== 0) {
        stars.push(<FontAwesomeIcon icon={faStarHalfAlt} key={i} className="star-icon" />);
      } else {
        stars.push(<FontAwesomeIcon icon={emptyStar} key={i} className="star-icon empty" />);
      }
    }
    return stars;
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!home) {
    return <div>Loading...</div>;
  }

  return (
    <div className="home-details-container">
      <h2>{home.location}</h2>
      
      {/* Carousel for displaying all images */}
      <Carousel showThumbs={true} className="image-carousel">
        {home.images.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Home image ${index + 1}`} className="home-image" />
          </div>
        ))}
      </Carousel>
      
      <div className="home-details-text">
  <p><strong>Location:</strong> {home.location}</p>
  <p><strong>Price:</strong> ${home.price} per night</p>
  <p><strong>Bedrooms:</strong> {home.numberOfBedRooms}</p>
  <p><strong>Description:</strong> {home.description}</p>
  <p><strong>Facilities:</strong> {home.facilities || 'Not available'}</p>
  <p><strong>Proximity to Malls:</strong> {home.proximityToMalls || 'Not specified'} km</p>
  <p><strong>Proximity to Airport:</strong> {home.proximityToAirport || 'Not specified'} km</p>
  {/* <p><strong>Phone Number:</strong> {home.phoneNumber}</p> */}
  <p><strong>Name of Host:</strong> {home.nameOfHost}</p>
  {/* <p><strong>Available Date:</strong> {home.availableDate}</p> */}
  <p><strong>Type of Home:</strong> {home.typeOfPome}</p>
  <p><strong>Rating:</strong> {renderStars(home.rating)}</p>
  <div className="home-details-actions">
  <a 
    href={`mailto:khahtech2010@gmail.com?subject=Reservation Request for the property at ${home.location}&body=Hello, I would like to make a reservation for the property located at ${home.location}. Could you please provide me with more details regarding availability and pricing?`} 
    className="inquiry-button"
  >
    Make a Reservation
  </a>
</div>

</div>

    </div>
  );
};

export default PrivateBnBDetails;
