import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import PrivateBnB from './pages/PrivateBnB';
import PrivateBnBDetails from './components/details/PrivateBnBDetails';
import PrivateBnBForm from './components/create/PrivateBnBForm';
import HouseSale from './pages/HouseSale';
import HouseSaleDetails from './components/details/HouseSaleDetails';
import HouseSaleForm from './components/create/HouseSaleForm';
import Contact from './pages/Contact';
import Terms from './pages/Terms';
import NotFound from './pages/NotFound';
import './App.css'; // Ensure to include your global styles

const App = () => {
  return (
    <Router>
      <Navbar /> {/* Include the Navbar */}
      <div className="main-content">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/terms" element={<Terms />} />


          <Route exact path="/private_homes" element={<PrivateBnB />} />
          <Route path="/private_homes/:id" element={<PrivateBnBDetails />} />
          <Route path="/private_home/create" element={<PrivateBnBForm />} />
          <Route path="/private_home/edit/:id" element={<PrivateBnBForm />} />

          <Route exact path="/nyumbasales" element={<HouseSale />} />
          <Route path="/nyumbasales/:id" element={<HouseSaleDetails />} />
          <Route path="/nyumbasale/create" element={<HouseSaleForm />} />
          <Route path="/nyumbasale/update/:id" element={<HouseSaleForm />} />
          {/* Add other routes here as needed */}

          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
};

export default App;
